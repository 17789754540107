<template>
    <aside v-show="isAsideVisible" class="aside is-placed-left is-expanded">
        <div class="aside-tools brand">
            <div class="aside-tools-label">
                <router-link v-if="icon" to="/home" style="color: white;">
                    <b-icon :icon="icon" custom-size="default" />
                </router-link>
                <span v-else>
                    <router-link to="/home" style="color:white">
                        <b-icon icon="home" custom-size="default"></b-icon>
                        <b> {{ siteName }}</b>
                    </router-link>
                </span>
            </div>
        </div>

        <div class="menu is-menu-main" style="height: 100vh;overflow-y: auto;">
            <div v-for="(menuGroup, index) in menu" :key="index">
                
                    <p class="menu-label" v-if="typeof menuGroup === 'string'" :key="index">{{ menuGroup }}</p>

                    <ul v-else :key="index" @menu-click="menuClick(menuGroup)" :class="{'menu-list':!isSubmenuList}" style="height: 100vh;">
                        <!--AsideMenuList-->
                        <template v-for="(item,index) in menuGroup" v-if="mainMenuVisible(item)">
                            <li @menu-click="menuClick(item)" :item="item" :key="index" :class="{'is-active':isDropdownActive}" :style="{ display: itemVisible(item)}" style="min-height: fit-content;">
                                <component :is="componentIs(item)" :to="itemTo(item)" :href="itemHref(item)" @click="menuClick(item)" exact-active-class="is-active" :class="{'has-icon':!!item.icon, 'has-dropdown-icon':hasDropdown(item)}">
                                    <!--<b-icon v-if="item.icon" :icon="item.icon" :class="{ 'has-update-mark' : item.updateMark }" custom-size="default" />-->
                                    <span v-if="item.label" :class="{'menu-item-label':!!item.icon}">{{ item.label }}</span>
                                    <!--<div v-if="hasDropdown(item)" class="dropdown-icon">
                <b-icon :icon="dropdownIcon(item)" custom-size="default" />
            </div>-->
                                </component>

                                <ul v-if="hasDropdown(item)" class="menu-list" style="overflow-y: auto;">
                                    <li v-for="(item,index) in item.menu" @menu-click="menuClick(item.menu)" :item="item.menu" :key="index" :class="{'is-active':isDropdownActive}" :style="{ display: itemVisible(item)}">
                                        <component :is="componentIs(item)" :to="itemTo(item)" :href="itemHref(item)" @click="menuClick(item)" exact-active-class="is-active" :class="{'has-icon':!!item.icon, 'has-dropdown-icon':hasDropdown(item)}">
                                            <b-icon v-if="item.icon" :icon="item.icon" :class="{ 'has-update-mark' : item.updateMark }" custom-size="default" />
                                            <span v-if="item.label" :class="{'menu-item-label':!!item.icon}">{{ item.label }}</span>
                                            <div v-if="hasDropdown(item)" class="dropdown-icon">
                                                <b-icon :icon="dropdownIcon(item)" custom-size="default" />
                                            </div>
                                        </component>
                                    </li>
                                </ul>
                            </li>
                        </template>
                    </ul>
            </div>
        </div>
    </aside>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CSideMenu',
  components: {},
  props: {
    menu: {
      type: Array,
      default: () => []
    },
    siteName: {
      type: String
    }
  },
  computed: {
    ...mapState([
      'isAsideVisible',
      'userRole'
    ])
  },
  data () {
    return {
      isSubmenuList: false,
      isDropdownActive: false,
      item: {},
      icon: null
    }
  },
  methods: {
    itemVisible (item) {
      if (item.menu !== undefined) {
            item.menu.forEach(menuItem => {
                if (menuItem.authType.includes(this.userRole)) {
                    return ''
                } else {
                    return 'none'
                }
            })
        } else {
            if (item.authType !== undefined) {
                if (item.authType.includes(this.userRole)) {
                    return ''
                }
            }
            return 'none'
        }
        return ''
    },
    mainMenuVisible (menuItem) {
        if (menuItem.authType.includes(this.userRole)) {
            return true
        } else {
            return false
        }
    },
    componentIs (item) {
        return item.to ? 'router-link' : 'a'
    },
    hasDropdown (item) {
        return !!item.menu
    },
    dropdownIcon (item) {
        return (this.isDropdownActive && item.menu) ? 'chevron-up' : 'chevron-down'
    },
    itemTo (item) {
        return item.to ? item.to : null
    },
    itemHref (item) {
        return item.href ? item.href : null
    },
    menuClick (item) {
        this.$emit('menu-click', item)
        if (item.menu) {
            this.isDropdownActive = (!this.isDropdownActive)
        }
    }
    }
}
</script>

<style>
    .brand {
        background-color: #00d1b2 !important;
        color: white !important;
    }
</style>
