<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top is-primary">
    <div class="navbar-brand">
      <a class="navbar-item" @click.prevent="menuToggleMobile">
        <b-icon :icon="menuToggleMobileIcon"/>
      </a>
    </div>
    <div class="navbar-start">
        <power-search-bar></power-search-bar>
    </div>
    <div class="navbar-brand is-right">
      <a class="navbar-item navbar-item-menu-toggle is-hidden-desktop" @click.prevent="menuNavBarToggle">
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default"/>
      </a>
    </div>
    <div class="navbar-menu fadeIn animated faster" :class="{'is-active':isMenuNavBarActive}">
        <div class="navbar-end">
            <div @click="toggle" class="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar" :class="{ 'is-hoverable':isHoverable, 'is-active':isDropdownActive }">
                <a class="navbar-link is-arrowless">
                    <div class="is-user-avatar">
                        <img :src="newUserAvatar" :alt="userName">
                    </div>
                    <div class="is-user-name">
                        <span>{{ user.account.name }}</span>
                    </div>
                    <b-icon :icon="toggleDropdownIcon" custom-size="default" />
                </a>
                <div slot="dropdown" class="navbar-dropdown">
                    <router-link to="/profile" class="navbar-item" exact-active-class="is-active">
                        <b-icon icon="account" custom-size="default"></b-icon>
                        <span>My Profile</span>
                    </router-link>
                    <hr class="navbar-divider">
                    <a class="navbar-item" @click="logout">
                        <b-icon icon="logout" custom-size="default"></b-icon>
                        <span>Log Out</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import { auth } from '@/services/msal-service'
export default {
  name: 'SNavBar',
  components: {
      PowerSearchBar: () => import('@/components/atoms/PowerSearchBar.vue')
  },
  data () {
    return {
      isMenuNavBarActive: false,
      isDropdownActive: false,
      isHoverable: false
    }
  },
  computed: {
    menuNavBarToggleIcon () {
      return (this.isMenuNavBarActive) ? 'close' : 'dots-vertical'
    },
    menuToggleMobileIcon () {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    darkModeToggleIcon () {
      return this.isDarkModeActive ? 'white-balance-sunny' : 'weather-night'
    },
    toggleDropdownIcon () {
      return this.isDropdownActive ? 'chevron-up' : 'chevron-down'
    },
    newUserAvatar () {
      return `https://eu.ui-avatars.com/api/?name=${this.userFirstName}+${this.userLastName}`
    },
    ...mapState([
      'user',
      'isNavBarVisible',
      'isAsideMobileExpanded',
      'isDarkModeActive',
      'userName',
      'userFirstName',
      'userLastName',
      'userAvatar'
    ])
  },
  created () {
    window.addEventListener('click', this.forceClose)
  },
  beforeDestroy () {
    window.removeEventListener('click', this.forceClose)
  },
  methods: {
    menuToggleMobile () {
      this.$store.commit('asideMobileStateToggle')
    },
    menuNavBarToggle () {
      this.isMenuNavBarActive = (!this.isMenuNavBarActive)
    },
    darkModeToggle () {
      this.$store.commit('darkModeToggle')
    },
    toggle () {
      if (!this.isHoverable) {
        this.isDropdownActive = !this.isDropdownActive
      }
    },
    forceClose (e) {
      if (!this.$el.contains(e.target)) {
        this.isDropdownActive = false
      }
    },
    logout() {
      auth.logout();
      this.$store.commit('user', null)
      this.$store.commit('SET_CLIENTS', null)
      this.$store.commit('setSelectedClient', null)
    },
  }
}
</script>
